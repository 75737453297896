<script setup>
import { onMounted } from "vue";
import Carriers from "./Carriers.vue";
import PageTitleCard from "@/components/PageTitleCard.vue";
import { setPageTitle } from "../../utils/pageTitle";
import { useRouter } from "vue-router";

const router = useRouter();

onMounted(() => {
  setPageTitle("Carriers");
});

const addNewCarrier = () => {
  router.push("/carriers/new");
};
</script>

<template>
  <PageTitleCard justifyCard="space-between">
    <template #actions>
      <div class="d-flex-end ml-auto mr-2">
        <v-btn flat class="font-weight-bold" color="primary" @click="addNewCarrier"
          >New Carrier</v-btn
        >
      </div>
    </template>
  </PageTitleCard>
  <div id="carrier-grid-wrapper">
    <Carriers />
  </div>
</template>

<style scoped>
#carrier-grid-wrapper {
  height: 100%;
  padding-top: 0.25%;
}
</style>
